<template>
  <div class="head" :style="{height:height}">
   <!--  标题  -->
    <Row style="width: 100%;height: 5%;margin-top: 15px">
      <Col span="24">
        <div class="bt" style="height: 60px;">
          <img :src="bt" width="100%" height="50px"/>
          <p class="bt1">培训情况统计分析</p>
        </div>
      </Col>
    </Row>
 <!--  内容  -->
    <Row :gutter="17" style="width: 100%;height: 85%">
      <!--   第一列内容   -->
      <Col span="6" style="height: 100%">
        <Row style="height: 9%">
          <div class="NumberOfTeachers">
            <div class="Teachers">
              教师数量
            </div>
            <div class="NumberOfTeachers1">
              <div class="NumberOfTeachers2">0</div>
              <div class="NumberOfTeachers2">0</div>
              <div class="NumberOfTeachers2">3</div>
              <div class="NumberOfTeachers2">4</div>
              <div class="NumberOfTeachers2">2</div>
            </div>
            <div class="Teachers1">
              人
            </div>
          </div>
        </Row>
        <!--    数据统计      -->
        <Row style="height: 55%;">
          <div class="dataStatistics">
            <p style="color: rgb(255,255,255);font-size: 17px">数据统计</p>
          </div>

          <!--     招生人次     -->
            <div class="admissionsPeople">
              <div style="width: 13%;height: 60%;margin: 15px;float: left">
                <img :src="admissionsPeople" width="100%" height="100%" />
              </div>
              <div style="float: left;margin-top: 15px">
                <div style="color: white">
                  招生人次
                </div>
                <div style="color:rgb(0,255,255);font-size: 20px">
                  560
                </div>
              </div>
              <div  style="float: left;margin: 50px 15px 15px 20px">
                <p align="center">
                  <span  style="color: white;font-size:10px">环比去年</span>
                  <span>&nbsp;</span>
                  <span  style="color: springgreen;font-size:10px">▲10%</span>
                </p >
              </div>
              <div style="width: 30%;height: 40%;float: left;margin: 35px 0 15px 25px">
                <img :src="riseBar" width="100%" height="100%" />
              </div>
            </div>
          <!--     开班数量     -->
          <div class="ClassNumber">
            <div style="width: 13%;height: 60%;margin: 15px;float: left">
              <img :src="ClassNumber" width="100%" height="100%" />
            </div>
            <div style="float: left;margin-top: 15px">
              <div style="color: white">
                开班数量
              </div>
              <div style="color:rgb(0,255,255);font-size: 20px">
                3560
              </div>
            </div>
            <div  style="float: left;margin: 50px 15px 15px 20px">
              <p align="center">
                <span  style="color: white;font-size:10px">环比去年</span>
                <span>&nbsp;</span>
                <span  style="color: springgreen;font-size:10px">▲10%</span>
              </p >
            </div>
            <div style="width: 30%;height: 40%;float: left;margin: 35px 0 15px 25px">
              <img :src="riseBar" width="100%" height="100%" />
            </div>
          </div>
          <!--     报考人次     -->
          <div class="staying">
            <div style="width: 13%;height: 60%;margin: 15px;float: left">
              <img :src="staying" width="100%" height="100%" />
            </div>
            <div style="float: left;margin-top: 15px">
              <div style="color: white">
                报考人次
              </div>
              <div style="color:rgb(0,255,255);font-size: 20px">
                3560
              </div>
            </div>
            <div  style="float: left;margin: 50px 15px 15px 20px">
              <p align="center">
                <span  style="color: white;font-size:10px">环比去年</span>
                <span>&nbsp;</span>
                <span  style="color: springgreen;font-size:10px">▲10%</span>
              </p >
            </div>
            <div style="width: 30%;height: 40%;float: left;margin: 35px 0 15px 25px">
              <img :src="riseBar" width="100%" height="100%" />
            </div>
          </div>
          <!--     人均学时     -->
          <div class="ClassHoursPerCapita">
            <div style="width: 13%;height: 60%;margin: 15px;float: left">
              <img :src="ClassHoursPerCapita" width="100%" height="100%" />
            </div>
            <div style="float: left;margin-top: 15px">
              <div style="color: white">
                人均学时
              </div>
              <div style="color:rgb(0,255,255);font-size: 20px">
                83.43
              </div>
            </div>
            <div  style="float: left;margin: 50px 15px 15px 20px">
              <p align="center">
                <span  style="color: white;font-size:10px">环比去年</span>
                <span>&nbsp;</span>
                <span  style="color: springgreen;font-size:10px">▲10%</span>
              </p >
            </div>
            <div style="width: 30%;height: 40%;float: left;margin: 35px 0 15px 25px">
              <img :src="riseBar" width="100%" height="100%" />
            </div>
          </div>
        </Row>
        <!--    师资情况      -->
        <Row style="height: 5%;margin-top: 2%">
          <div class="TeachingSituation">
            <p style="color: rgb(255,255,255);font-size: 17px">师资情况</p>
          </div>
        </Row>

        <Row style="height: 35%;margin-top: 3%">
          <div class="TeachingSituation1">
            <img :src="content" width="100%" height="100%" />
            <div class="TeachingSituation2">
              <teaching-situation-bar></teaching-situation-bar>
            </div>
          </div>
        </Row>
      </Col>

     <!--  第二列内容    -->
      <Col span="12" style="height: 100%">
     <!--    学员数量    -->
        <Row style="height: 9%;">
          <div class="student">
            <div class="student1">
              <div class="Number">
                学员数量
              </div>
              <div class="Number1">
                <div class="Number2">0</div>
                <div class="Number2">6</div>
                <div class="Number2">2</div>
                <div class="Number2">5</div>
                <div class="Number2">5</div>
              </div>
              <div class="Number3">
                人
              </div>
            </div>
          </div>
        </Row>
        <!--    培训情况    -->
         <Row style="height: 5%;">
           <div class="trainingSituation">
             <p style="color: rgb(255,255,255);font-size: 17px">培训情况</p>
           </div>
         </Row>

        <Row style="height: 49%;margin-top: 1%">
          <div class="trainingSituation1">
            <img :src="content" width="100%" height="100%" />
            <div class="trainingSituation2">
              <b-i-table></b-i-table>
            </div>
          </div>
        </Row>
        <!--    招生,报考,结业趋势    -->
        <Row style="height: 5%;margin-top: 1%">
          <div class="tendency">
            <p style="color: rgb(255,255,255);font-size: 17px">招生,报考,结业趋势</p>
          </div>
        </Row>

        <Row style="height: 35%;margin-top: 1%">
          <div class="tendency1">
            <img :src="content" width="100%" height="100%" />
            <div class="tendency2">
              <period-pie></period-pie>
            </div>
          </div>
        </Row>
      </Col>

     <!--   第三列内容   -->
      <Col span="6" style="height: 100%">
        <Row style="height: 9%">
          <div class="hourNumber">
            <div class="hour">
              课时数量
            </div>
            <div class="hour1">
              <div class="hour2">0</div>
              <div class="hour2">6</div>
              <div class="hour2">2</div>
              <div class="hour2">5</div>
              <div class="hour2">5</div>
            </div>
            <div class="hour3">
              课时
            </div>
          </div>
        </Row>
        <!--   合格率统计   -->
        <Row style="height: 5%">
          <div class="percentPass">
            <p style="color: rgb(255,255,255);font-size: 17px">合格率统计</p>
          </div>
        </Row>

        <Row style="height: 20%;margin-top: 3%">
          <div class="percentPass1">
            <img :src="content" width="100%" height="100%" />
            <!--   本期就业率   -->
            <div class="currentPeriod">
              <current-period-gauge></current-period-gauge>
            </div>
            <!--   本年就业率   -->
            <div class="currentYear">
              <current-year-gauge></current-year-gauge>
            </div>

            <!--   去年就业率   -->
            <div class="lastYear">
              <last-year-gauge></last-year-gauge>
            </div>
          </div>
        </Row>

        <!--   学时趋势   -->
        <Row style="height: 5%;margin-top: 3%">
          <div class="SchoolTrends">
            <p style="color: rgb(255,255,255);font-size: 17px">学时趋势</p>
          </div>
        </Row>

        <Row style="height: 20%;margin-top: 3%">
          <div class="SchoolTrends1">
            <img :src="content" width="100%" height="98%" />
            <div class="SchoolTrends2">
              <school-trends-line></school-trends-line>
            </div>
          </div>
        </Row>

        <!--   培训情况   -->
        <Row style="height: 5%;margin-top: 2%">
          <div class="trainingSituationRadar">
            <p style="color: rgb(255,255,255);font-size: 17px">培训情况</p>
          </div>
        </Row>

        <Row style="height: 34%;margin-top: 3%">
          <div class="trainingSituationRadar1">
            <img :src="content" width="100%" height="100%" />
            <div class="trainingSituationRadar2">
              <cultivate-radar></cultivate-radar>
            </div>
          </div>
        </Row>
      </Col>
    </Row>
  </div>
</template>

<script>
import bt from '@/static/img/标题.png'
import content from '@/static/img/content.png'
import admissionsPeople from '@/static/img/教师1.png'
import declineBar from '@/static/img/declineBar.png'
import riseBar from '@/static/img/riseBar.png'
import ClassNumber from '@/static/img/ClassNumber.png'
import staying from '@/static/img/staying.png'
import ClassHoursPerCapita from '@/static/img/ClassHoursPerCapita.png'
import TeachingSituationBar from "@/view/trainingSituationOrg/TeachingSituationBar";
import SchoolTrendsLine from "@/view/trainingSituationOrg/SchoolTrendsLine";
import currentPeriodGauge from "@/view/trainingSituationOrg/currentPeriodGauge";
import currentYearGauge from "@/view/trainingSituationOrg/currentYearGauge";
import lastYearGauge from "@/view/trainingSituationOrg/lastYearGauge";
import periodPie from "@/view/trainingSituationOrg/periodPie";
import cultivateRadar from "@/view/trainingSituationOrg/cultivateRadar";
import BITable from "@/view/trainingSituationOrg/trainingSituationOrgTable";
export default {
  name: "trainingSituationOrg",
  components: {
    TeachingSituationBar,
    SchoolTrendsLine,currentPeriodGauge,currentYearGauge,lastYearGauge,periodPie,cultivateRadar,BITable},
  data(){
    return {
      bt:bt, //标题图片
      content:content, //内容图片
      admissionsPeople:admissionsPeople, //招生人次
      declineBar:declineBar, //下降柱状图
      riseBar:riseBar, //上升柱状图
      ClassNumber:ClassNumber, //开班数量图标
      staying:staying, //报考人次图标
      ClassHoursPerCapita:ClassHoursPerCapita, //人均学时图标
      height:window.innerHeight+'px',

    }
  }

}
</script>

<style scoped>
/*页面背景渐变色*/
.head{
  background: radial-gradient(ellipse,#00102E 0,#000B27 100%);
  overflow:hidden
}

/*标题*/
.bt{
  position:relative;/*相対定位*/
  display: flex;
  justify-content: center; /* 水平居中 */
}
.bt1 {
  position:absolute;/*絶対定位*/
  top:5%;
  left:45%;
  color:rgb(0,255,255);
  font-size: 27px;

}

/*教师数量*/
.NumberOfTeachers {
  width: 100%;
  height: 100%;
  position:relative;/*相対定位*/
  /*display:flex;*/
}
.NumberOfTeachers1 {
  width: 50%;
  height: 60px;
  display: flex;
  align-items: center;     /* 垂直居中 */
  margin-left: 38%;
  position:absolute;/*絶対定位*/
  top:0;
  left:-4%;
}
.NumberOfTeachers2 {
  width: 40px;
  height: 36px;
  box-shadow:0px 0px 10px #4185F0 inset;
  margin-left: 3%;
  font-size: 24px;
  text-align: center;
  color:rgb(0,255,255);

}
.Teachers {
  width: 40%;
  height: 60px;
  position:absolute;/*絶対定位*/
  line-height: 60px;
  font-size: 30px;
  text-align: center;
  color: rgb(0,255,255);
  top:0;
  left:0;
}
.Teachers1 {
  width: 12%;
  height: 60px;
  position:absolute;/*絶対定位*/
  line-height: 60px;
  font-size: 30px;
  text-align: center;
  color: rgb(0,255,255);
  top:0;
  right:3%;
}

/*学生数量*/
.student {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center; /* 水平居中 */
}
.student1 {
  width: 40%;
  height: 60px;
  position:relative;/*相対定位*/

}
.Number {
  width: 40%;
  height: 60px;
  position:absolute;/*絶対定位*/
  line-height: 60px;
  font-size: 30px;
  text-align: center;
  color: rgb(0,255,255);
  top:0;
  left:0;
}
.Number1 {
  width: 55%;
  height: 60px;
  display: flex;
  align-items: center;     /* 垂直居中 */
  margin-left: 38%;
  position:absolute;/*絶対定位*/
  top:0;
  left:0;
}
.Number2 {
  width: 40px;
  height: 36px;
  box-shadow:0px 0px 10px #4185F0 inset;
  margin-left: 3%;
  font-size: 24px;
  text-align: center;
  color:rgb(0,255,255);

}
.Number3 {
  width: 12%;
  height: 60px;
  position:absolute;/*絶対定位*/
  line-height: 60px;
  font-size: 30px;
  text-align: center;
  color: rgb(0,255,255);
  top:0;
  right:-6%;
}

/*课时数量*/
.hourNumber {
  width: 100%;
  height: 100%;
  position:relative;/*相対定位*/
}
.hour {
  width: 40%;
  height: 60px;
  position:absolute;/*絶対定位*/
  line-height: 60px;
  font-size: 30px;
  text-align: center;
  color: rgb(0,255,255);
  top:0;
  left:0;
}
.hour1 {
  width: 50%;
  height: 60px;
  display: flex;
  align-items: center;     /* 垂直居中 */
  margin-left: 33%;
  position:absolute;/*絶対定位*/
  top:0;
  left:0;
}
.hour2 {
  width: 40px;
  height: 36px;
  box-shadow:0px 0px 10px #4185F0 inset;
  margin-left: 3%;
  font-size: 24px;
  text-align: center;
  color:rgb(0,255,255);

}
.hour3 {
  width: 20%;
  height: 60px;
  position:absolute;/*絶対定位*/
  line-height: 60px;
  font-size: 30px;
  text-align: center;
  color: rgb(0,255,255);
  top:0;
  right: -1%;
}

.dataStatistics {
  width: 100%;
  height: 9%;
  background-color: rgb(0,26,77);
  text-align: center;
  line-height: 40px;
}

/*招生人次*/
.admissionsPeople {
  width: 100%;
  height: 19%;
  background-color: rgb(8,26,62);
  margin-top: 3%;
}

/*开班数量*/
.ClassNumber {
  width: 100%;
  height: 19%;
  background-color: rgb(8,26,62);
}

/*报考人次*/
.staying {
  width: 100%;
  height: 19%;
  background-color: rgb(8,26,62);
}

/*人均学时*/
.ClassHoursPerCapita {
  width: 100%;
  height: 19%;
  background-color: rgb(8,26,62);
}

/*师资情况*/
.TeachingSituation {
  width: 100%;
  height: 100%;
  background-color: rgb(0,26,77);
  text-align: center;
  line-height: 40px;
}

.TeachingSituation1 {
  width: 100%;
  height: 100%;
  position:relative;/*相対定位*/
}

.TeachingSituation2 {
  width: 92%;
  height: 88%;
  position:absolute;/*絶対定位*/
  bottom: 5%;
  left:2%;
}
/*培训情况*/
.trainingSituation {
  width: 100%;
  height: 100%;
  background-color: rgb(0,26,77);
  text-align: center;
  line-height: 40px;
}

.trainingSituation1 {
  width: 100%;
  height: 100%;
  position:relative;/*相対定位*/
}

.trainingSituation2 {
  width: 95%;
  height: 87%;
  position:absolute;/*絶対定位*/
  top:9%;
  left:2%;
}

/*招生,报考,结业趋势*/
.tendency {
  width: 100%;
  height: 100%;
  background-color: rgb(0,26,77);
  text-align: center;
  line-height: 40px;
}

.tendency1 {
  width: 100%;
  height: 100%;
  position:relative;/*相対定位*/
}

.tendency2 {
  width: 96%;
  height: 88%;
  position:absolute;/*絶対定位*/
  bottom: 5%;
  left:2%;
}

/*合格率统计*/
.percentPass {
  width: 100%;
  height: 100%;
  background-color: rgb(0,26,77);
  text-align: center;
  line-height: 40px;
}

.percentPass1 {
  width: 100%;
  height: 100%;
  position:relative;/*相対定位*/
}
/*本期就业率*/
.currentPeriod {
  width: 31%;
  height: 90%;
  position:absolute;/*絶対定位*/
  bottom: 5%;
  left:3%;
}

/*本年就业率*/
.currentYear {
  width: 30%;
  height: 90%;
  position:absolute;/*絶対定位*/
  bottom: 5%;
  left:36%;
}

/*去年就业率*/
.lastYear {
  width: 31%;
  height: 90%;
  position:absolute;/*絶対定位*/
  bottom: 5%;
  left:68%;
}

/*学时趋势*/
.SchoolTrends {
  width: 100%;
  height: 100%;
  background-color: rgb(0,26,77);
  text-align: center;
  line-height: 40px;
}

.SchoolTrends1 {
  width: 100%;
  height: 100%;
  position:relative;/*相対定位*/

}
.SchoolTrends2 {
  width: 92%;
  height: 89%;
  position:absolute;/*絶対定位*/
  bottom: 5%;
  left:2%;

}
/*培训情况雷达*/
.trainingSituationRadar {
  width: 100%;
  height: 100%;
  background-color: rgb(0,26,77);
  text-align: center;
  line-height: 40px;
}
.trainingSituationRadar1 {
  width: 100%;
  height: 100%;
  position:relative;/*相対定位*/
}
.trainingSituationRadar2 {
  width: 94%;
  height: 88%;
  position:absolute;/*絶対定位*/
  bottom: 5%;
  left:2%;
}
</style>